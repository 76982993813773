header {
    position: relative;
    width: 100%;
    height: 100vh;
}

.header-news {
    position: absolute;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.news-item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: -1;

    &__img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        object-position: center;
        object-fit: cover;
        z-index: -1;
    }
}

.news-item video {
    width: 100%;
    height: 100vh;
    object-position: center;
    object-fit: cover;
}

.news-item img {
    width: 100%;
    height: 100vh;
    object-position: center;
    object-fit: cover;
}

.news-content {
    position: absolute;
    bottom: 80px;
    width: 100%;
    z-index: 8;
    color: #FFFFFF;
}

.news-content h1 {
    font-family: 'Acumin Pro Bold', sans-serif;
    text-transform: uppercase;
    color: var(--blauw);
    line-height: 50px;
}

.overlay-top {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 6;
    /*background: rgba(0,0,0,0.4);*/
    background-image: linear-gradient(rgba(0,0,0,0.0), rgba(0,0,0,0.9));
    //background-image: linear-gradient(rgba(0,0,0,0.0), rgba(0,164,180,0.9));
}

#news-hover {
    z-index: 8;
    /* Gebruik lijn hieronder met news-header */
    /*margin-top: -200px;*/
}

.hide-news {
    display: none;
}

.active-news {

}

.header-title {
    font-family: $font-acumin-arachno !important;
    color: transparent !important;
    -webkit-text-stroke: 5px $white;
    font-size: 7vw;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-transform: uppercase;

    @media (max-width: $breakpoint-md) {
        -webkit-text-stroke: 3px $white;
        font-size: 9vw;
    }
}

