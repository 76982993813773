#info {
    background: $black-a;

    h2 {
        font-family: $font-acumin-arachno;
        font-size: $font-h2;
        text-transform: uppercase;
        color: $blue;

        @media (max-width: $breakpoint-sm) {
            font-size: $font-h3;
        }

        span {
            color: $white;
            font-size: $font-small;
        }
    }

    .info {
        &__content {
            display: flex;
            flex-direction: row;
            gap: 1rem;
            justify-content: space-evenly;
            width: 100%;
            margin-bottom: 80px;

            @media (max-width: $breakpoint-md) {
                flex-direction: column;
            }
        }

        &__item {
            color: $white;
            flex-basis: 33.33%;
            text-align: center;
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
            font-family: $font-broken-glass;
            font-size: $font-h4;

            i {
                font-size: $font-h2;
                color: $yellow;
            }
        }
    }
}
