.btn {
    text-transform: uppercase;
    font-family: $font-raleway !Important;
    font-weight: $font-weight-bold !Important;
    font-size: $font-h6 !Important;
    padding: 20px 30px !important;
    transition: 0.5s ease-in-out all;

    &:focus {
        box-shadow: none !important;
    }

    &-arachno {
        color: $white !important;
        font-family: $font-raleway !Important;
        text-transform: uppercase !Important;
        font-weight: $font-weight-bold !Important;
        font-size: $font-h6 !Important;
        background-color: $blue;
        border-radius: $br-10;

        &:hover {
            background-color: $dark-gray;
        }

        &:focus {
            background-color: $dark-gray;
        }
    }

    &-arachno-link {
        color: $dark-gray !important;
        font-family: $font-raleway !Important;
        text-transform: uppercase !Important;
        font-weight: $font-weight-bold !Important;
        font-size: $font-h6 !Important;
        background-color: $transparent;
        border-radius: $br-10;

        &:hover {
            background-color: $dark-gray;
            color: $white !important;
        }

        &:focus {
            background-color: $dark-gray;
        }
    }

    &-edit {
        background-color: $blue !important;
        font-family: $font-raleway !Important;
        text-transform: uppercase !Important;
        font-weight: $font-weight-bold !Important;
        font-size: $font-h6 !Important;
        border-radius: $br-10;

        &:hover {
            opacity: 0.8;
            color: $black-a !important;
        }

        &:focus {
            background-color: $blue;
            color: $black-a !important;
        }
    }

    &-delete {
        background-color: $red !important;
        font-family: $font-raleway !Important;
        text-transform: uppercase !Important;
        font-weight: $font-weight-bold !Important;
        font-size: $font-h6 !Important;
        border-radius: $br-10;

        &:hover {
            opacity: 0.8;
            color: $black-a !important;
        }

        &:focus {
            background-color: $red;
            color: $black-a !important;
        }
    }
}
