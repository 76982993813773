.tm-news-h1 {
    font-family: $font-acumin-arachno;
    font-size: 2.5rem;
    font-weight: 700;
    text-transform: uppercase;
    color: $white;
    margin-bottom: 150px;
}

#praktisch {
    margin-bottom: 150px;

    h2 {
        font-family: $font-acumin-arachno;
        font-size: $font-h2;
        text-transform: uppercase;
        color: $blue;
        margin-bottom: 50px;

        span {
            color: $white;
            font-size: $font-small;
        }
    }

    .prak__tabs {
        .nav-item {
            .nav-link {
                color: $white;
                text-transform: uppercase;
                font-family: $font-acumin-arachno;

                &.active {
                    background: $yellow;
                    border-radius: $br-10;
                    color: $black;
                }
            }
        }
    }

    .tab-content {
        color: $white;

        h3 {
            font-size: 20px;
            margin-bottom: 15px;
            color: $blue;
        }

        .tickets_info {
            display: flex;
            flex-direction: column;
            margin-bottom: 80px;

            strong {
                color: $blue;
            }
        }
    }

    p {
        color: $white;
    }
}

#faq-sec {
    margin-bottom: 150px;

    .section-title {
        font-family: $font-acumin-arachno;
        font-size: $font-h2;
        text-transform: uppercase;
        color: $blue;
        margin-bottom: 50px;

        span {
            color: $white;
            font-size: $font-small;
        }
    }
}

#lineup-page {
    margin-bottom: 150px;

    .section-title {
        font-family: $font-acumin-arachno;
        font-size: $font-h2;
        text-transform: uppercase;
        color: $blue;
        margin-bottom: 50px;

        span {
            color: $white;
            font-size: $font-small;
        }
    }

    .coming-soon {
        font-family: $font-acumin-arachno;
        font-size: 2.5rem;
        font-weight: 700;
        text-transform: uppercase;
        color: $white;
        margin-bottom: 150px;
    }

    .lineup {
        &__wrapper {
            display: flex;
            flex-direction: column;
            gap: 1rem;

            @media (max-width: $breakpoint-md) {
                gap: 2.5rem;
            }
        }

        &__item {
            display: flex;
            flex-direction: row;
            gap: 1rem;
            align-items: center;

            @media (max-width: $breakpoint-md) {
                flex-direction: column;
            }
        }

        &__left {
            flex-basis: 33.33%;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            align-items: center;
            background: $blue;
            color: $white;
            border-radius: $br-10;
            font-family: $font-acumin-arachno;
            font-size: $font-h4;
            padding: 1rem;
            text-transform: uppercase;
            min-height: 100px;

            @media (max-width: $breakpoint-md) {
                flex-basis: 100%;
                width: 100%;
            }

            &__time {
                display: flex;
                align-items: center;
                height: 100%;
                flex-grow: 1;
            }
        }

        &__right {
            display: flex;
            flex-direction: row;
            gap: 1rem;

            &__img {
                width: 100px;
                height: 100px;
                border-radius: $br-10;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            &__info {
                display: flex;
                flex-direction: column;
                gap: .3rem;
                padding: 5px;

                &__title {
                    font-family: $font-acumin-arachno;
                    font-size: $font-h4;
                    text-transform: uppercase;
                    color: $blue;
                }

                &__text {
                    color: $white;
                }
            }
        }
    }
}
