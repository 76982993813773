#cookie-btn {
    background: $blue none repeat scroll 0 0;
    border-radius: $br-10;
    bottom: 20px;
    color: $white;
    cursor: pointer;
    height: 44px;
    position: fixed;
    left: 20px;
    text-align: center;
    width: 44px;
    display: block;
    padding: 8px 0;
    z-index: 99999999 !important;

    &:hover {
        background-color: $dark-gray;
    }
}

.BackTo {
    background: $blue none repeat scroll 0 0;
    border-radius: $br-10;
    bottom: 20px;
    color: $white;
    cursor: pointer;
    height: 44px;
    position: fixed;
    right: 20px;
    text-align: center;
    width: 44px;
    z-index: 9;
    display: block;
    padding: 8px 0;

    &:hover {
        background-color: $dark-gray;
    }

    i {
        color: #fff;
        font-size: 20px;
        font-weight: 700;
        margin-top: 2px;
    }
}

#footer-one {
    text-align: center;
    position: relative;
    padding-top: 70px;
    padding-bottom: 70px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    &:before {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        content: '';
        background: rgba(0, 0, 0, .65);
    }

    &:after {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-image: url('/images/lines-2.png');
        content: '';
        background-repeat: no-repeat;
        background-position: center;
        z-index: 0;
    }

    .book-seat-content {
        position: relative;
        z-index: 1 !important;
        margin-bottom: 50px;

        h2 {
            color: $white;
            font-size: 40px;
            font-weight: 700;
            margin-bottom: 20px;
            text-transform: uppercase;
            font-family: $font-acumin-arachno;

            span {
                font-size: 14px;
                font-family: $font-roboto;
                display: block;
                font-weight: 400;
                color: #888888;
                margin-bottom: 20px;
                text-transform: uppercase;
            }
        }
    }

    .ts-footer-newsletter {
        position: relative;
        z-index: 1 !important;

        .ts-newsletter {
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
            background-color: $blue;
            padding: 55px 0 60px;
            position: absolute;
            width: 100%;
            z-index: 1;
            border-radius: $br-10;
        }
    }

    .ts-newsletter-content {
        h2 {
            color: $white;
            font-size: 40px;
            font-weight: 700;
            margin-bottom: 20px;
            text-transform: uppercase;
            font-family: $font-acumin-arachno;

            span {
                font-size: 14px;
                font-family: $font-roboto;
                display: block;
                font-weight: 400;
                text-transform: uppercase;
            }
        }
    }
}

#footer-two {
    background: $black-a none repeat scroll 0 0;
    padding: 300px 0 100px;

    .footer-menu {
        text-align: center;
        margin-bottom: 25px;

        ul {
            padding: 0;
            margin: 0;

            li {
                display: inline-block;

                a {
                    color: #a8a8ad;
                    margin: 0 20px;
                    position: relative;
                    transition: all 0.4s ease;
                    -webkit-transition: all 0.4s ease;

                    &:hover {
                        color: $blue;
                    }

                    &:link {
                        text-decoration: none;
                    }

                    &:before {
                        position: absolute;
                        left: -24px;
                        top: 1px;
                        bottom: 0;
                        margin: auto;
                        content: '';
                        background: #5f5e6f;
                        width: 5px;
                        height: 5px;
                        border-radius: 50%;
                        -webkit-border-radius: 50%;
                        -ms-border-radius: 50%;
                    }
                }

                &:first-child a:before {
                    display: none;
                }
            }
        }
    }

    .copyright-text p {
        color: #a8a8ad;
    }
}
