// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');
@import '~noty/src/noty';
@import '~noty/src/themes/bootstrap-v4';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Variables
@import 'variables';

@import 'main';
