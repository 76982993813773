// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$orange: #f6993f;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;

$white: #fff;
$gray-100: #f7fafc;
$gray-200: #edf2f7;
$gray-300: #e2e8f0;
$gray-400: #cbd5e0;
$gray-500: #a0aec0;
$gray-600: #718096;
$gray-700: #4a5568;
$gray-800: #2d3748;
$gray-900: #1a202c;
$black: #000;

$blue: #00a4b4;
$red: #E92C25;
$black-a: #161616;
$yellow: #FEED01;
$dark-gray: #313A37;
$transparent: transparent;

// Fonts
$font-family-sans-serif: 'Nunito', sans-serif;
$font-family-serif: 'Nunito', sans-serif;
$font-family-monospace: 'Nunito', sans-serif;
$font-raleway: 'Raleway', sans-serif;
$font-roboto: 'Roboto', sans-serif;
$font-broken-glass: 'Broken Glass', sans-serif;
$font-acumin-pro: 'Acumin Pro Bold', sans-serif;

$font-acumin-arachno: 'Acumin Arachnophobia', sans-serif;

$font-freedom: 'Freedom', sans-serif;
$font-redemtion: 'Redemtion', sans-serif;

// alternatives fonts
$font-dusk: 'Dusk Regular', sans-serif;
$font-gladius: 'Gladius', sans-serif;
$font-jersey: 'Jersey', sans-serif;
$font-news-paper: 'News Paper', sans-serif;
$font-translator: 'Translator', sans-serif;
$font-vegapunk: 'Vegapunk', sans-serif;

// Border radius
$br-0: 0;
$br-5: 5px;
$br-10: 10px;
$br-20: 20px;
$br-30: 30px;
$br-40: 40px;

// Font sizes
$font-h1: 3.5rem;
$font-h2: 2.5rem;
$font-h3: 2rem;
$font-h4: 1.5rem;
$font-h5: 1.25rem;
$font-h6: 1rem;
$font-p: 1rem;
$font-small: 0.8rem;
$font-very-small: 0.7rem;

// Font weights
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;
$font-weight-bolder: 900;

// Breakpoints
$breakpoint-xs: 0;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;

// Spacing
$spacing-0: 0;
$spacing-1: 0.25rem;
$spacing-2: 0.5rem;
$spacing-3: 0.75rem;
$spacing-4: 1rem;
$spacing-5: 1.25rem;
$spacing-6: 1.5rem;
$spacing-7: 1.75rem;
$spacing-8: 2rem;
$spacing-9: 2.25rem;
$spacing-10: 2.5rem;
$spacing-11: 2.75rem;







