#navigatie {
    position: absolute;
    width: 100%;
    z-index: 99999999999999999 !important;
}

#nav-block {
    position: relative;
}

.act-link {
    position: relative;
}

.act-link::before {
    content: "";
    position: absolute;
    top: 100%;
    width: 100%;
    left: 0;
    height: 3px;
    border-radius: $br-10;
    background: $blue;
}

.btn-tickets {
    background: $blue;
    border-radius: $br-10;
    font-size: 16px;
    font-weight: $font-weight-bold;
    color: $white;
    text-transform: uppercase;
    height: 50px;
    line-height: 50px;
    padding: 0 30px;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    outline: none;
    text-decoration: none;
    cursor: pointer;
}
.btn-tickets:hover {
    background: $dark-gray;
    color: $white !important;
}
#primary-nav {
    padding: 10px;
    font-family: $font-acumin-pro;
}
#primary-nav ul {
    float: right;
}
#primary-nav ul li {
    display: inline-block;
    float: left;
}
#primary-nav ul li:not(:first-child) {
    margin-left: 25px;
}
#primary-nav ul li a {
    display: inline-block;
    outline: none;
    color: $white;
    font-size: 16px;
    line-height: 50px;
    text-decoration: none;
    letter-spacing: 0.04em;
}
#primary-nav ul li a:hover {
    color: $blue;
    text-decoration: none;
}

.spider-mobile {
    display: none !important
}

@media screen and (max-width: $breakpoint-lg) {
    .spider-mobile {
        display: block !important;
    }

    #primary-nav .nav-container {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: -1;
        background: $black-a;
        display: none;
        transition: all 0.2s ease;
    }
    #primary-nav .nav-container ul {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        padding-left: 0;
    }
    #primary-nav .nav-container ul li {
        display: block;
        float: none;
        width: 100%;
        text-align: center;
        margin-bottom: 10px;
    }
    #primary-nav .nav-container ul li:nth-child(1) a {
        transition-delay: 0.2s;
    }
    #primary-nav .nav-container ul li:nth-child(2) a {
        transition-delay: 0.3s;
    }
    #primary-nav .nav-container ul li:nth-child(3) a {
        transition-delay: 0.4s;
    }
    #primary-nav .nav-container ul li:nth-child(4) a {
        transition-delay: 0.5s;
    }
    #primary-nav .nav-container ul li:nth-child(5) a {
        transition-delay: 0.6s;
    }
    #primary-nav .nav-container ul li:not(:first-child) {
        margin-left: 0;
    }
    #primary-nav .nav-container ul li a {
        margin: 10px 0;
        opacity: 0;
        color: $white;
        font-size: 24px;
        font-weight: 600;
        transform: translateY(-20px);
        transition: all 0.2s ease;
    }
    .nav-open {
        position: fixed;
        right: 0;
        top: 0;
        display: block;
        width: 55px;
        height: 55px;
        cursor: pointer;
        z-index: 9999;
        background: $blue;
        border-radius: 0 0 0 10px;
    }
    .nav-open i {
        display: block;
        width: 20px;
        height: 2px;
        background: $white;
        border-radius: 2px;
        margin-left: 18px;
    }
    .nav-open i:nth-child(1) {
        margin-top: 18px;
    }
    .nav-open i:nth-child(2) {
        margin-top: 4px;
        opacity: 1;
    }
    .nav-open i:nth-child(3) {
        margin-top: 4px;
    }

    #secondary-nav {
        font-size: 18px !important;
    }
}
#nav:checked + .nav-open {
    /*transform: rotate(45deg);*/
}
#nav:checked + .nav-open i {
    transform: rotate(45deg);
    background: $white;
    transition: transform 0.2s ease;
}
#nav:checked + .nav-open i:nth-child(1) {
    transform: translateY(6px) rotate(180deg);
}
#nav:checked + .nav-open i:nth-child(2) {
    opacity: 0;
}
#nav:checked + .nav-open i:nth-child(3) {
    transform: translateY(-6px) rotate(90deg);
}
#nav:checked ~ .nav-container {
    z-index: 9990;
    display: block;
}
#nav:checked ~ .nav-container ul li a {
    opacity: 1;
    transform: translateY(0);
}
.hidden {
    display: none;
}


#secondary-nav {
    color: $white;
    padding: 20px;
    font-family: $font-broken-glass;
    font-size: 30px;

    .color-blauw {
        color: $blue;
    }
}
