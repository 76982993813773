.lcc-modal {
    border-radius: $br-10;

    &__title {
        font-family: $font-acumin-pro;
        font-weight: $font-weight-bolder;
    }
}

.page-banner-area {
    padding-top: 150px;

    .page-banner-title {
        text-align: center;
        color: $white;
        font-family: $font-acumin-arachno;
        margin-bottom: 150px;

        h2 {
            margin-bottom: 25px;
        }

        .breadcrumb {
            text-align: center;
            font-family: $font-roboto;
            display: flex;
            flex-direction: row;
            justify-content: center;
            gap: 1rem;

            a {
                color: $white;
                text-decoration: none;
                font-weight: $font-weight-bolder;

                &:hover {
                    color: $blue;
                }
            }
        }
    }
}

#cookie {
    color: $white;

    h3 {
        font-family: $font-acumin-pro;
        font-weight: $font-weight-bolder;
        color: $blue;
        text-transform: uppercase;
        font-size: 1.5rem;
        margin-bottom: 25px;
    }

    .check,
    .privemodus,
    .google,
    .microsoft,
    .firefox,
    .safari {
        padding: 10px 20px;
    }

    .google,
    .microsoft,
    .firefox,
    .safari {
        margin-right: 10px;
    }

    strong {
        color: $red;
    }

    a {
        color: $white;
        text-decoration: underline;
        font-weight: $font-weight-bolder;

        &:hover {
            color: $blue;
        }
    }

    ul {
        li {
            margin-bottom: 10px;
        }
    }
}

.background-voorwaarden {
    background-size: 100%;
    background-position: center;
}
