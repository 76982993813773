#artiesten {
    background: $black-a;
    padding-top: 50px;
    padding-bottom: 50px;
    position: relative;
    overflow: hidden;

    h2 {
        font-family: $font-acumin-arachno;
        font-size: $font-h2;
        text-transform: uppercase;
        color: $blue;
        z-index: 2 !important;
        position: relative;

        span {
            color: $white;
            font-size: $font-small;
            z-index: 2 !important;
        }
    }

    .artist {
        &__grid {
            display: flex;
            gap: 2rem;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            z-index: 2 !important;

            @media screen and (max-width: $breakpoint-sm) {
                flex-direction: column;
                gap: 0;
            }

            &__item {
                flex-basis: 33%;
                transition: .3s ease-in-out all;
                transform-origin: center;
                z-index: 2 !important;

                @media screen and (max-width: $breakpoint-sm) {
                    padding: 40px;
                }

                &:hover {
                    cursor: pointer;
                    transform: scale(1.03);
                }

                &__img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: $br-10;
                    z-index: 2 !important;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: $br-10;
                    }
                }
            }
        }
    }

    .background-spider {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }

    .button-artist {
        position: relative;
        z-index: 2 !important;
    }
}
