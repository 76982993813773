#tickets {
    padding: 40px 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    h2 {
        font-family: $font-acumin-arachno;
        font-size: $font-h2;
        text-transform: uppercase;
        color: $white;

        span {
            color: $white;
            font-size: $font-small;
        }
    }

    &.gradient {
        position: relative;

        &:before {
            position: absolute;
            left: 0;
            top: 0;
            background-image: linear-gradient(144deg, rgba(0,164,180,0.5) 0%, rgba(49,58,55,0) 60%, rgba(49,58,55,0) 100%, rgba(49,58,55,0) 100%);
            width: 100%;
            height: 100%;
            content: '';
        }
    }

    .card {
        border: none;
        border-radius: $br-20;
        padding: 40px;
        min-height: 500px;

        &-header {
            border-bottom: none;
            background: $white;
            border-radius: $br-20 $br-20 0 0;
            text-align: center;
            padding: 0;

            .ticket {
                &-type {
                    font-family: $font-acumin-arachno;
                    font-size: $font-h6;
                    text-transform: uppercase;
                    color: $dark-gray;
                    margin-bottom: 20px;
                }

                &-price {
                    font-family: $font-raleway;
                    font-size: $font-h2;
                    font-weight: $font-weight-bolder;
                    margin-bottom: 20px;

                    .currency {
                        font-size: $font-p;
                        margin-right: 5px;
                    }
                }

                &-time {
                    font-size: $font-p;
                }
            }

        }

        .progress-bar {
            background: $white;
            border-radius: $br-20;
            height: 10px;
            margin-bottom: 20px;

            .progress {
                background: $blue;
                border-radius: $br-20;
                height: 5px;
            }
        }

        &-body {
            text-align: center;
            padding: 0;
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
            justify-content: space-between;
        }
    }

    .shap2 {
        position: absolute;
        right: 0;
        top: 40px;
        z-index: 1;
    }
}

