body {
    background-color: $black-a;
}

.check{
    background: #04B479;
    border-radius: 5px;
    padding: 5px;
    color: black;
}

.privemodus{
    background: yellow;
    border-radius: 5px;
    padding: 5px;
    color: black;
}

.google{
    color: white;
    background: red;
    padding: 5px;
    border-radius: 5px;
}

.microsoft{
    color: white;
    background: #0175CF;
    padding: 5px;
    border-radius: 5px;
}

.firefox{
    color: white;
    background: orange;
    padding: 5px;
    border-radius: 5px;
}

.safari{
    color: white;
    background: #3394F4;
    padding: 5px;
    border-radius: 5px;
}


#cookie h3{
    color: white;
}

#cookie strong{
    color: white;
}
