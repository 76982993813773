#faq {
    padding-top: 30px;
    padding-bottom: 80px;

    h2 {
        font-family: $font-acumin-arachno;
        font-size: $font-h2;
        text-transform: uppercase;
        color: $blue;
        z-index: 2 !important;
        position: relative;

        span {
            color: $white;
            font-size: $font-small;
            z-index: 2 !important;
        }
    }
}

/* FAQ section */
.faq-section .accordion-item {
    border-radius: $br-10;
    box-shadow: unset;
    color: $white;
    background: transparent;
    border: none;
}

.faq-section .accordion-header {
    border: none;
}

.faq-section .accordion-button {
    box-shadow: unset;
    color: $black;
    background: $white !important;
    border-radius: $br-10 !important;
    padding: 25px !important;
}

.faq-section .accordion-button:not(.collapsed) {
    box-shadow: unset;
    color: $white;
    background: $blue !important;
    border-radius: $br-10 $br-10 0 0 !important;
}

.accordion-button::after {
    // background-image: url('../images/icons/angle-down-solid.svg');
    background-size: 1rem;
}

.accordion-button:not(.collapsed)::after {
    // background-image: url('../images/icons/angle-down-solid-white.svg');
    transform: rotate(-180deg);
}

.faq-section .accordion-header button {
    background: transparent;
    font-family: 'Acumin Pro Bold', sans-serif;
}

.faq-section .accordion-header button:focus {
    box-shadow: none;
}

.faq-section .accordion-body {
    background: $blue !important;
    border-radius: 0 0 $br-10 $br-10 !important;
    padding: 25px !important;
}

.faq-section a {
    color: $white;
    font-weight: bold;
}

.faq-section a:hover {
    color: $red;
}
