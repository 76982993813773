#partners {
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: auto !important;
    padding-top: 80px !important;
    padding-bottom: 80px !important;

    h2 {
        font-family: $font-acumin-arachno;
        font-size: $font-h2;
        text-transform: uppercase;
        color: $blue;
        z-index: 2 !important;
        position: relative;

        span {
            color: $white;
            font-size: $font-small;
            z-index: 2 !important;
        }
    }

    .owl-theme .owl-nav {
        text-align: right !important;
        margin-bottom: 30px;
    }

    .owl-carousel .owl-nav-partner button.owl-next, .owl-carousel .owl-nav-partner button.owl-prev, .owl-carousel button.owl-dot {
        background: 0 0;
        color: $blue;
        border: 2px solid $blue;
        border-radius: $br-10;
        padding: 10px 15px !important;
        font: inherit;
    }

    .owl-carousel .owl-nav-partner button.owl-next:hover, .owl-carousel .owl-nav-partner button.owl-prev:hover, .owl-carousel button.owl-dot:hover {
        background: $blue;
        color: $white;
    }

    .owl-item {
        img {
            border-radius: $br-10;
        }
    }
}
